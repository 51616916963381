.btn {
    display: flex;
    background: var(--color-bg-primary);
    outline: none;
    gap: 8px;
    padding: 12px;
    flex-direction: column;
    border: 0;
    box-shadow: inset 0 0 0 1px var(--color-border-primary);
    border-radius: 8px;
    text-align: left;
    overflow: hidden;
    min-width: 134px !important;
    cursor: pointer;
}

.btn:hover {
    box-shadow: inset 0 0 0 2px #98b1d5;
}

.btn img {
    height: 22px;
    width: fit-content;
}

.btn.selected {
    box-shadow: inset 0 0 0 2px #2174ef;
}
